import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { SceneContext } from "../reducer";

const Record = ({ id, records, nameMap }) => {
    const [childrenOpen, setChildrenOpen] = useState(false);
    const record = records.find(record => record._id === id);
    const filteredChildren = record && record.children ? record.children.filter(child => child !== "") : [];

    return record ?
        <div className="tree-row">
            <div className="row row-space-between">
                <div className="row row-parent" onClick={() => setChildrenOpen(!childrenOpen)}>
                    {filteredChildren.length > 0 ?
                        <div className="open-children">
                            <i className={`fa-solid fa-angle-${childrenOpen ? 'down' : 'right'}`} />
                        </div>
                        : <div className="open-children"></div>
                    }
                    <div>
                    <Link className="link link-text" to={`/view/${record._id}`}>
                    {record.short_description}
                    </Link>
                        
                    </div>
                </div>
                <div className="row row-links row-right">
                    <Link className="link link-action link-caret" to={`/edit/${record._id}`}>
                        Edit
                    </Link>
                </div>
            </div>
            {childrenOpen ? <div className="row row-children">
                {filteredChildren.length > 0
                    ? <div className="row row-column">
                        {filteredChildren.map(child => <Record
                            id={child}
                            key={`child-${child}`}
                            nameMap={nameMap}
                            records={records}
                        />)}</div> : null}
                {/* <div className="row row-column create-linked-scene">
                    <Link className="link link-caret" to={`/create/${record._id}`}>
                        Create New Linked Scene
                    </Link>
                </div> */}
            </div> : null}
        </div> : null
};

const TreeView = () => {
    const [state] = useContext(SceneContext);
    const topScenes = state.records.filter(record => record.parentId === '');

    return <div className="scene-tree">
        {topScenes.map(record => <Record
            id={record._id}
            key={record._id}
            nameMap={state.nameMap}
            records={state.records}
        />)}
    </div>;
};

export default TreeView;