import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SceneContext } from "../reducer";

const FullRecord = ({ listView = false, record }) => {
    const [state] = useContext(SceneContext);
    const filteredChildren = record && record.children ? record.children.filter(child => child !== "") : [];

    return record && record._id ? 
    <div className="scene-block" id={record._id}>
        <div className="row">
            <div className="scene-description-block">
                {record.parentId
                    ? <div className="row scene-parent row-vertical-bottom">
                        <h4>Parent scene:</h4>
                        <Link className="link link-caret" to={`/view/${record.parentId}`}>
                            {state.nameMap[record.parentId]}
                        </Link>
                    </div>
                    : ""}
                <div className="row row-space-between scene-short-description">
                    <div className="row row-description">
                        <h3>
                            {listView ? <Link className="link link-caret" to={`/view/${record._id}`}>
                                {record.short_description}
                            </Link> : <span>{record.short_description}</span>}
                        </h3>
                        <div className="tags"><span className={`tag tag-${record.type}`}>{record.type || 'Basic'}</span></div>
                    </div>
                    <div className="row row-links row-right">
                        <Link className="button-link button-link-mini" to={`/edit/${record._id}`}>
                            Edit
                        </Link>
                    </div>
                </div>
                <div className="scene-description">
                    {record.description}
                </div>
                {filteredChildren.length > 0
                    ? <div className="row scene-children row-column">
                        <h4>Linked scenes:</h4>
                        {filteredChildren.map(child => <div key={child}><Link className="link link-caret" to={`/view/${child}`}>
                            {state.nameMap[child]}
                        </Link></div>)}
                    </div>
                    : ""}
                <div className="row row-column create-linked-scene">
                    <Link className="link link-caret" to={`/create/${record._id}`}>
                        Create New Linked Scene
                    </Link>
                </div>
            </div>
        </div>
    </div> : null;
};

export default FullRecord;