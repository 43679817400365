import React from "react";

export const SceneContext = React.createContext();

export const initialState = {
    nameMap: {},
    records: [],
    shouldFetch: true
};

const mapNames = records => {
  const recordMap = {};
  records.forEach(record => {
    recordMap[record._id] = record.short_description;
  });
  return recordMap;
};

export const sceneReducer = (state, action) => {
  switch (action.type) {
    case 'setRecords':
      return {
        ...state,
        nameMap: mapNames(action.records),
        records: action.records,
        shouldFetch: false
      };
    case 'setFetch':
      return {
        ...state,
        shouldFetch: action.shouldFetch
      }
    default:
      return { ...state };
  }
}
