import React, { useEffect, useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import { initialState, SceneContext, sceneReducer } from "./reducer";

// We import all the components we need in our app
import Navbar from "./components/navbar";
import RecordList from "./components/recordList";
import Edit from "./components/edit";
import View from "./components/viewNote";
import Create from "./components/create";

const App = () => {
  const [state, dispatch] = useReducer(sceneReducer, initialState);
  const url = process.env.NODE_ENV === 'production' ? 'https://scene-server.herokuapp.com' : `http://${process.env.REACT_APP_LOCALHOST_IP}:5000`;
  // This method fetches the records from the database.
  useEffect(
    () => {
      if (state.shouldFetch) {
        async function getRecords() {
          const response = await fetch(`${url}/record/`);

          if (!response.ok) {
            const message = `An error occurred: ${response.statusText}`;
            window.alert(message);
            return;
          }

          const records = await response.json();
          dispatch({ records, type: "setRecords" });
        }

        getRecords();
        return;
      }
    },
    [state.shouldFetch, url]
  );

  // This method will delete a record
  // async function deleteRecord(id) {
  //   await fetch(`https://scene-server.herokuapp.com/${id}`, {
  //     method: "DELETE"
  //   });

  //   const newRecords = state.records.filter(el => el._id !== id);
  //   dispatch({records: newRecords, type: 'setRecords'});
  // }

  return (
    <SceneContext.Provider value={[state, dispatch]}>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<RecordList view="full" />} />
        <Route exact path="/view-list" element={<RecordList view="list" />} />
        <Route exact path="/view-tree" element={<RecordList view="tree" />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/view/:id" element={<View />} />
        <Route path="/create" element={<Create />} />
        <Route path="/create/:id" element={<Create />} />
      </Routes>
    </SceneContext.Provider>
  );
};

export default App;
