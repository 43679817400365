import React from "react";
import { NavLink } from "react-router-dom";
import "./css/navbar.css";

// Here, we display our Navbar
export default function Navbar() {
  return (
      <nav className="navbar">
        <div className="row">
          <NavLink className="navbar-brand" to="/">
            <i className="fa-solid fa-house" />
            Story Notes
          </NavLink>
        </div>
      </nav>
  );
}
