import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { SceneContext } from "../reducer";
import FullRecord from './fullRecord';
import TreeView from './treeView';
import "./css/recordList.css";

const Record = ({ record }) =>
  <div className="table-row">
    <div className="row row-space-between">
      <div>
        {record.short_description}
      </div>
      {record.parent
        ? <div>
          {record.parent}
        </div>
        : ""}
      <div>
        <Link className="link link-caret" to={`/view/${record._id}`}>
          View
        </Link>
        <Link className="link link-action link-caret" to={`/edit/${record._id}`}>
          Edit
        </Link>
      </div>
    </div>
  </div>;

export default function RecordList({ view = "list" }) {
  const [state] = useContext(SceneContext);
  const [selectOpen, setSelectOpen] = useState(false);

  // This following section will display the table with the records of individuals.
  return (
    <div>
      <div className="row row-vertical-bottom row-space-between row-header">
        <div className="row row-vertical-bottom">
          <h2>Scenes</h2>
          <p className="link">View As:</p>
          <ul
            className={`fake-select ${selectOpen ? 'open' : ''}`}
            onClick={() => setSelectOpen(!selectOpen)}
          >
            <li className="selected-clone">
              <span>{view}</span>
            </li>
            <li className="choices">
              <ul>
                <li className={`choice ${view === "full" ? 'selected' : ''}`}>
                  <Link className="list-link" to="/">
                    Full Scene
                  </Link>
                </li>
                <li className={`choice ${view === "list" ? 'selected' : ''}`}>
                  <Link className="list-link" to="/view-list">
                    List
                  </Link>
                </li>
                <li className={`choice ${view === "tree" ? 'selected' : ''}`}>
                  <Link className="list-link" to="/view-tree">
                    Tree
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <Link className="button-link" to="/create">
          Create Note
        </Link>
      </div>
      <div className="row">
        {view === "list" || view === "full" ? <div className="table table-striped">{state.records.map(
          record =>
            view === "list"
              ? <Record
                record={record}
                key={record._id}
              />
              : <FullRecord
                record={record}
                listView
                key={record._id}
              />
        )}</div> : <TreeView />}
      </div>
    </div>
  );
}
