import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router";
import { SceneContext } from "../reducer";
import SharedForm from "./sharedForm";
import "./css/form.css";

export default function Edit() {
  const [state, dispatch] = useContext(SceneContext);
  const [form, setForm] = useState({
    description: "",
    parentId: "",
    short_description: "",
    type: "",
    records: []
  });
  const params = useParams();
  const url = process.env.NODE_ENV === 'production' ? 'https://scene-server.herokuapp.com' : `http://${process.env.REACT_APP_LOCALHOST_IP}:5000`;
  const navigate = useNavigate();

  useEffect(
    () => {
      const id = params.id.toString();
      let record =
        state.records.length > 0 ? state.records.find(r => r._id === id) : null;

      if (record === null) {
        async function fetchData() {
          const id = params.id.toString();
          const response = await fetch(
            `${url}/record/${params.id.toString()}`
          );

          if (!response.ok) {
            const message = `An error has occurred: ${response.statusText}`;
            window.alert(message);
            return;
          }

          const record = await response.json();
          if (!record) {
            window.alert(`Record with id ${id} not found`);
            navigate("/");
            return;
          }

          setForm(record);
        }

        fetchData();
      } else {
        setForm(record);
      }
      return;
    },
    [params.id, navigate, state.records, url]
  );

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm(prev => {
      return { ...prev, ...value };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    const editedPerson = {
      description: form.description,
      parentId: form.parentId,
      short_description: form.short_description,
      type: form.type
    };

    // This will send a post request to update the data in the database.
    await fetch(`${url}/update/${params.id}`, {
      method: "POST",
      body: JSON.stringify(editedPerson),
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (form.parentId) {
      await fetch(`${url}/updateChildren/${form.parentId}`, {
        method: "POST",
        body: JSON.stringify({child: params.id}),
        headers: {
          "Content-Type": "application/json"
        }
      });
    }

    dispatch({ shouldFetch: true, type: "setFetch" });
    navigate(`/view/${params.id}`);
  }

  // This following section will display the form that takes input from the user to update the data.
  return <SharedForm
    form={form}
    onSubmit={onSubmit}
    sceneList={state.records}
    updateForm={updateForm}
  />;
}
