import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { SceneContext } from "../reducer";
import FullRecord from './fullRecord';
import './css/form.css';

export default function View() {
    const params = useParams();
    const [state] = useContext(SceneContext);
    const [scene, setScene] = useState({});
    const url = process.env.NODE_ENV === 'production' ? 'https://scene-server.herokuapp.com' : `http://${process.env.REACT_APP_LOCALHOST_IP}:5000`;

    useEffect(() => {
        const id = params.id.toString();
        let record = state.records.length > 0 ? state.records.find(r => r._id === id) : null;

        if (record === null) {
            async function fetchData() {
                const response = await fetch(
                    `${url}/record/${params.id.toString()}`
                );

                if (!response.ok) {
                    const message = `An error has occurred: ${response.statusText}`;
                    window.alert(message);
                    return;
                }

                record = await response.json();
                setScene(record);
                return;
            }
        } else {
            setScene(record);
        }
    }, [params.id, state.records, url]);

    return scene ? <div className="row row-column">
        <FullRecord
            record={scene}
        />
    </div> : null;
}
