import React from "react";
import './css/form.css';

const SharedForm = ({
    form,
    onSubmit,
    sceneList = [],
    updateForm
}) => <div className="row">
        <form onSubmit={onSubmit}>
            <div className="form-left">
                <div className="form-group">
                    <label htmlFor="parentId">Parent Scene</label>
                    <div className="select">
                        <select
                            id="parentId"
                            name="parentId"
                            onChange={(e) => {
                                updateForm({ parentId: e.target.value })
                            }}
                            value={form.parentId}
                        >
                            <option value="">--Please choose an option--</option>
                            {sceneList.map(scene => <option key={scene._id} value={scene._id}>{scene.short_description}</option>)}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="short_description">Scene Short Description</label>
                    <input
                        type="text"
                        className="form-control"
                        id="short_description"
                        value={form.short_description}
                        onChange={(e) => updateForm({ short_description: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        type="text"
                        className="form-control"
                        id="description"
                        value={form.description}
                        onChange={(e) => updateForm({ description: e.target.value })}
                    />
                </div>
            </div>
            <div className="form-right">
                <div className="form-group self-start">
                    <label>Scene Type</label>
                    <div className="form-check form-check-inline">
                        <label htmlFor="typeBasic" className="form-check-label">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                id="typeBasic"
                                value="basic"
                                checked={form.type === "basic"}
                                onChange={(e) => updateForm({ type: e.target.value })}
                            />
                            Basic
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <label htmlFor="typePhone" className="form-check-label">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                id="typePhone"
                                value="phone"
                                checked={form.type === "phone"}
                                onChange={(e) => updateForm({ type: e.target.value })}
                            />
                            Phone
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <label htmlFor="typePuzzle" className="form-check-label">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                id="typePuzzle"
                                value="puzzle"
                                checked={form.type === "puzzle"}
                                onChange={(e) => updateForm({ type: e.target.value })}
                            />
                            Puzzle
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <input
                        type="submit"
                        value="Save"
                        className="button-link"
                    />
                </div>
            </div>
        </form>
    </div>;

export default SharedForm;
