import React, { useState, useContext } from "react";
import { useParams, useNavigate } from "react-router";
import { SceneContext } from "../reducer";
import SharedForm from "./sharedForm";
import './css/form.css';

export default function Create() {
  const [state, dispatch] = useContext(SceneContext);
  const params = useParams();
  const url = process.env.NODE_ENV === 'production' ? 'https://scene-server.herokuapp.com' : `http://${process.env.REACT_APP_LOCALHOST_IP}:5000`;
  const navigate = useNavigate();
  const id = params && params.id ? params.id.toString() : null;

  const [form, setForm] = useState({
    description: "",
    parentId: id || "",
    short_description: "",
    type: ""
  });

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    // When a post request is sent to the create url, we'll add a new record to the database.
    const newPerson = { ...form };

    await fetch(`${url}/record/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPerson),
    })
    .then(res => res.json())
    .then(data => {
      if (form.parentId) {
        fetch(`${url}/updateChildren/${form.parentId}`, {
          method: "POST",
          body: JSON.stringify({child: data.insertedId}),
          headers: {
            "Content-Type": "application/json"
          }
        });
      }
      dispatch({ shouldFetch: true, type: "setFetch" });
      setForm({
        description: "",
        parentId: "",
        short_description: "",
        type: ""
      });
      if (data && data.insertedId) navigate(`/view/${data.insertedId}`);
    })
    .catch((error) => {
      window.alert(error);
      return;
    });
  }

  // This following section will display the form that takes the input from the user.
  return <SharedForm 
    form={form}
    onSubmit={onSubmit}
    sceneList={state.records}
    updateForm={updateForm}
  />;
}
